<template>
  <el-dialog v-model="dialog_visible" title="成员编辑" :close-on-click-modal="false" :destroy-on-close="true" draggable @close="onClose">
      <el-form :ref="form_name" :model="form_data" label-width="80px">
        <el-form-item label="用户名">
            <el-input v-if="!is_create" v-model="staff.user_name" disabled></el-input>
            <el-input v-else v-model="form_data.user_name"></el-input>
        </el-form-item>
        <el-form-item v-if="is_create" label="密码">
            <el-input v-model="form_data.password"></el-input>
        </el-form-item>
<!--        <el-form-item label="权限">-->
<!--            <el-checkbox v-model="form_data.can_material" label="原料"></el-checkbox>-->
<!--            <el-checkbox v-model="form_data.can_process" label="加工"></el-checkbox>-->
<!--            <el-checkbox v-model="form_data.can_product" label="成品"></el-checkbox>-->
<!--            <el-checkbox v-model="form_data.can_sales" label="销售"></el-checkbox>-->
<!--        </el-form-item>-->
        <el-form-item label="操作权限">
          <div class="auth-view">
            <div class="auth-group">原料</div>
            <div class="auth-list">
              <el-checkbox v-model="form_data.can_material_view" label="查看"></el-checkbox>
              <el-checkbox v-model="form_data.can_material_edit" label="编辑"></el-checkbox>
              <el-checkbox v-model="form_data.can_material_remove" label="删除"></el-checkbox>
              <el-checkbox v-model="form_data.can_material_audit" label="审核"></el-checkbox>
              <el-checkbox v-model="form_data.can_material_un_audit" label="反审"></el-checkbox>
            </div>
          </div>
          <div class="auth-view">
            <div class="auth-group">加工</div>
            <div class="auth-list">
              <el-checkbox v-model="form_data.can_process_view" label="查看"></el-checkbox>
              <el-checkbox v-model="form_data.can_process_edit" label="编辑"></el-checkbox>
              <el-checkbox v-model="form_data.can_process_remove" label="删除"></el-checkbox>
              <el-checkbox v-model="form_data.can_process_audit" label="审核"></el-checkbox>
              <el-checkbox v-model="form_data.can_process_un_audit" label="反审"></el-checkbox>
            </div>
          </div>
          <div class="auth-view">
            <div class="auth-group">成品</div>
            <div class="auth-list">
              <el-checkbox v-model="form_data.can_product_view" label="查看"></el-checkbox>
              <el-checkbox v-model="form_data.can_product_edit" label="编辑"></el-checkbox>
              <el-checkbox v-model="form_data.can_product_remove" label="删除"></el-checkbox>
              <el-checkbox v-model="form_data.can_product_audit" label="审核"></el-checkbox>
              <el-checkbox v-model="form_data.can_product_un_audit" label="反审"></el-checkbox>
            </div>
          </div>
          <div class="auth-view">
            <div class="auth-group">销售</div>
            <div class="auth-list">
              <el-checkbox v-model="form_data.can_sales_view" label="查看"></el-checkbox>
              <el-checkbox v-model="form_data.can_sales_edit" label="编辑"></el-checkbox>
              <el-checkbox v-model="form_data.can_sales_remove" label="删除"></el-checkbox>
              <el-checkbox v-model="form_data.can_sales_audit" label="审核"></el-checkbox>
              <el-checkbox v-model="form_data.can_sales_un_audit" label="反审"></el-checkbox>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="is_create" label="启用">
            <el-switch v-model="form_data.enabled"></el-switch>
        </el-form-item>
        <el-form-item>
            <el-button @click="onSubmitTap">提交</el-button>
        </el-form-item>
      </el-form>
  </el-dialog>
</template>

<script>
  import { getDetail, submit } from '../../api/cmp/staff'

  export default {
    name: 'staff-detail-window',
    data(){
        return{
            dialog_visible:false,
            id:'',
            staff:{},
            form_data:{
                user_name:'',
                password:'',
                enabled:'',
                can_material:false,
                can_process:false,
                can_product:false,
                can_sales:false,
            },
            form_name:'form_name',
            submit_callback:undefined,
            cancel_callback:undefined,
        }
    },
    created(){
        // this.initPage()
    },
    computed:{
        is_create(){
          // return !(this.staff&&this.staff.id)
          return !this.id && this.dialog_visible
        }
    },
    methods: {
      initPage () {
          this.getDetail()
      },
      getDetail () {
          if(this.id>0){
              getDetail(this.id).then(resp=>{
                  console.log('resp:',resp)
                  this.staff = resp.data
                  // this.form_data.id = this.staff.id
                  this.form_data = this.staff
                  console.log('this.staff:',this.staff)
              })
          }else{
              this.form_data = {}
          }
      },
      setID(id){
          this.id = id
          return this
      },
      // setStaff(staff){
      //     this.staff = staff
      //     return this
      // },
      onSubmitTap () {
        submit(this.form_data).then(resp => {

            console.log('resp:',resp)
            if(this.submit_callback)this.submit_callback()
        })
      },
      setSubmitCallback(submit_callback){
          this.submit_callback = submit_callback
        return this
      },
      setCancelCallback(cancel_callback){
          this.cancel_callback = cancel_callback
        return this
      },
      show(){
          this.initPage()
          this.dialog_visible = true
      },
      hide(){
          this.dialog_visible = false
          // this.form_data = {}
          this.submit_callback = undefined
          this.cancel_callback = undefined
          this.staff= {}
          this.id = ''


      },
      onClose(){
        if(this.cancel_callback)this.cancel_callback()
      }
    }

  }
</script>

<style scoped>
  .auth-view{display:flex;width:100%;border:1px solid #CDD0D6;border-bottom-width:0;}
  .auth-view:last-child{border-bottom-width: 1px;}
  .auth-group{padding:0 16px;white-space:nowrap;border-right:1px solid #cdd0d6;}
  .auth-list{flex:1;padding:0 0 0 16px;}
  .auth-view /deep/.el-checkbox{margin-right:24px;}
  /deep/ .el-input.is-disabled .el-input__inner{color: #001529}
  /deep/.el-dialog, .el-message-box {
    width: 600px;
  }
  @media screen and (min-width:1200px) {
    /deep/.el-dialog, .el-message-box {
      width: 90%;
    }
  }

</style>
