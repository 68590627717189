<template>
  <el-dialog v-model="dialog_visible" title="密码重置" width="360px" :close-on-click-modal="false" :destroy-on-close="true" draggable @close="onClose">
    <el-form :ref="form_name" :model="form_data" label-width="80px">
      <el-form-item label="用户名">
        <el-input v-model="staff.user_name" disabled></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form_data.pass" type="password"></el-input>
      </el-form-item>
      <el-form-item label="密码确认">
        <el-input v-model="form_data.pass_confirm" type="password">></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="onSubmitTap">提交</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import { resetPass } from '../../api/cmp/staff'

  export default {
    name: 'staff-reset-pass-window',
    data(){
      return{
        dialog_visible:false,
        staff:{},
        form_data:{
          pass:'',
          pass_confirm:'',
        },
        form_name:'form_name',
        submit_callback:undefined,
        cancel_callback:undefined,
      }
    },
    created(){
      // this.initPage()
    },
    methods: {
      setStaff(staff){
        this.staff = staff
        return this
      },

      setID(id){
        this.id = id
        return this
      },
      onSubmitTap () {
        this.form_data.id=this.staff.id
        resetPass(this.form_data).then(resp => {
          console.log('resp:',resp)
          if(this.submit_callback)this.submit_callback()
        })
      },
      setSubmitCallback(submit_callback){
        this.submit_callback = submit_callback
        return this
      },
      setCancelCallback(cancel_callback){
        this.cancel_callback = cancel_callback
        return this
      },
      show(){
        // this.initPage()
        this.dialog_visible = true
      },
      hide(){
        this.dialog_visible = false
        this.form_data = {}
        this.submit_callback = undefined
        this.cancel_callback = undefined
        this.staff = {}
        this.id = ''
      },
      onClose(){
          if(this.cancel_callback)this.cancel_callback()
      }
    }

  }
</script>

<style scoped>
/deep/ .el-input.is-disabled .el-input__inner{color: #001529}
</style>
