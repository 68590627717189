import request from '@/utils/request'

export const getList = (page,size,params)=>{
  return request({
    url:'/api/cmp/staff/list',
    method:'get',
    params:{...params,page,size}
  });
}

export const getDetail = (id)=>{
  return request({
    url:'/api/cmp/staff/detail',
    method:'get',
    params:{id}
  })
}

export const submit = (data)=>{
  return request({
    url:'/api/cmp/staff/submit',
    method:'post',
    data:data
  })
}

export const setEnabled = (id,enabled)=>{
  return request({
    url:'/api/cmp/staff/set_enabled',
    method:'post',
    data:{id,enabled},
  })
}

export const resetPass = (data)=>{
  return request({
    url:'/api/cmp/staff/reset_pass',
    method:'post',
    data:data,
  })
}
