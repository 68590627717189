<template>
    <div class="rb-view">
      <div style="display:flex;justify-content: space-between;">
        <div>成员管理</div>
        <el-button @click="onCreateTap">新增</el-button>
      </div>
      <div class="search-view">
        <el-form :ref="search_form_name" :model="search_option" label-position="right" label-width="100px" size="default">
        </el-form>
      </div>

      <div>
        <el-table size="small" v-bind="table_attrs" :ref="list_table_name" :data="page_list" :fit="true" border style="margin-top:16px;" :stripe="true" highlight-current-row>
<!--          <el-table-column label="成员"></el-table-column>-->
          <el-table-column label="账号" prop="user_name" width="70" fixed="left" align="left"></el-table-column>
<!--          <el-table-column label="角色" prop="role">-->
<!--              <template #default="scope">-->
<!--                  <span>{{ROLE_MAP[scope.row.role]}}</span>-->
<!--              </template>-->
<!--          </el-table-column>-->
          <el-table-column label="最近登录" prop="login_at" :formatter="elTimeStampFormatTime"></el-table-column>
          <el-table-column label="登录次数" prop="logins"></el-table-column>
          <el-table-column label="登录IP" prop="login_ip"></el-table-column>
          <el-table-column label="状态"  width="70" fixed="right" align="center">
              <template #default="scope">
                  <el-switch v-model="scope.row.enabled" @change="onEnabledChange($event,scope.row)"></el-switch>
              </template>
          </el-table-column>

          <el-table-column label="操作" width="80" fixed="right" align="center">
            <template #default="scope">
              <el-button size="small" type="text" @click="onResetPassTap(scope.row)">重置密码</el-button>
              <el-button size="small" type="text" @click="onEditTap(scope.row)" style="margin-left: 0px;">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="list-page-view" style="margin-top:8px;">
        <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page_option.page" :page-sizes="[5,10, 20, 30, 50,100,200]" :page-size="page_option.size" layout="total, sizes, prev, pager, next, jumper" :total="page_option.total"></el-pagination>
      </div>


      <staff-detail-window :ref="ref_detail_name"></staff-detail-window>
      <staff-reset-pass-window :ref="res_reset_pass_name"></staff-reset-pass-window>
    </div>
</template>

<script>
  import { search_col_attrs, table_attrs } from '../../config/layout'
  import { getList, setEnabled } from '../../api/cmp/staff'
  import StaffDetailWindow from './staff-detail-window'
  import StaffResetPassWindow from './staff-reset-pass-window'
  import { formatTimestamp } from '../../utils/time'
  const ROLE_MAP = {
    1:'原料',
    2:'加工',
    3:'成品',
    4:'销售',
  }
  export default {
    name: 'user-list',
    components: { StaffResetPassWindow, StaffDetailWindow },
    data () {
      return {
        search_form_name: 'search_form_name',
        search_col_attrs,
        search_option: {},
        table_attrs,
        list_table_name: 'list_table_name',
        page_option: {
          page: 1,
          size: 20,
          total: 0
        },
        page_list: [],
        ref_detail_name:'ref_detail_name',
        res_reset_pass_name:'res_reset_pass_name',
        ROLE_MAP,
      }
    },
    created () {
        this.initPage()
    },
    methods: {
      initPage () {
          this.getPageList({page:1,size:this.page_option.size},this.search_option)
      },
      reloadPage () {
          this.getPageList({page:this.page_option.page,size:this.page_option.size},this.search_option)
      },
      getPageList (page_opt,search_opt) {
          this.loading=true
          getList(page_opt.page,page_opt.size,search_opt).then(resp=>{
              this.loading = false;
              this.page_list = resp.data.records||[];
              this.page_option.page = page_opt.page
              this.page_option.total = resp.data.total
              this.page_option.size = page_opt.size
          }).catch(()=>{
              this.loading = false
          })
      },
      handleSizeChange (pageSize) {
        this.getPageList({
          page: 1,
          size: pageSize
        }, this.search_option);
      },
      handleCurrentChange (pageIndex) {
        this.getPageList({
          page: pageIndex,
          size: this.page_option.size
        }, this.search_option)
      },
      onCreateTap(){
          let rd = this.$refs[this.ref_detail_name]
          if(!rd)return

          rd.setSubmitCallback(()=>{
              rd.hide()
          }).setCancelCallback(()=>{
              rd.hide()
          }).show()
      },
      onResetPassTap(row){
          console.log('row',row)
          let rd = this.$refs[this.res_reset_pass_name]
          if(!rd)return
          rd.setStaff(row).setSubmitCallback(()=>{rd.hide();this.reloadPage()}).
            setCancelCallback(()=>{rd.hide();this.reloadPage()}).show()

      },
      onEnabledChange(enabled,row){
          setEnabled(row.id,enabled).finally(()=>{this.reloadPage()})
      },
      onEditTap(row){
        let rd = this.$refs[this.ref_detail_name]
        if(!rd)return
        console.log('onEditTap:',row)
        rd.setID(row.id).setSubmitCallback(()=>{
          rd.hide()
          this.reloadPage()
        }).setCancelCallback(()=>{
          rd.hide()
        }).show()
      },
      elTimeStampFormatTime(row,column,val){
        return formatTimestamp(val)
      }
    }
  }
</script>

<style scoped>
    /deep/.el-dialog, .el-message-box {
        width: 600px;
      }
  @media screen and (max-width: 1200px){
    /deep/.el-dialog, .el-message-box {
      width: 90%;
    }
    /deep/.el-pagination__jump{
      display: none !important;
    }
  }
</style>
